<template>
  <div id="divOynaKazanWrapper" class="container play-win">
    <div class="wrapper">
      <BrandInfoCard
        :key="i"
        v-for="(marathon, i) in activeMarathons"
        :title="marathon.marathonName"
        sizeLevel="9"
        :marathon="marathon"
        :iframeGame="marathon.marathonId === -1"
        :bg-color="marathon.bg"
        @click="showInfo(marathon)"
      />
    </div>
    <VueText
      v-if="showDailyMarathonMessage"
      class="daily_marathon_message"
      sizeLevel="5"
      weightLevel="3"
      >{{ dailyMarathonMessage }}</VueText
    >
    <BrandSelectModal
      class="marathon-modal-wrapper"
      :showModal="modal.show"
      :plainTextModal="true"
      :title="modal.title"
      :headerClass="modal.headerClass"
      @click="modal.show = false"
      @onModalClose="modal.show = false"
      :bottomPadding="120"
    >
      <div class="scores-container">
        <div class="static-desc" v-html="modal.description"></div>
        <VueText
          v-if="modal.points && modal.points.length > 0"
          class="scores-title"
          sizeLevel="6"
          weightLevel="4"
          >Geçmiş Oyun Sonuçları</VueText
        >
        <div v-if="modal.points && modal.points.length > 0" class="scores-table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Oyun</th>
                <th>Oyun Tarihi</th>
                <th>Kazanılan Puan</th>
                <th>Sıra</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in modal.points" :key="`key_${i}`">
                <td>{{ item.description }}</td>
                <td>{{ item.date | parseISODate }}</td>
                <td>{{ item.exchangePoints | currencyFormat }}</td>
                <td>{{ item.rank }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </BrandSelectModal>

    <div v-if="chefShopAvailable" class="virtual-visit">
      <span>
        Geçen ay tüm uygulamalardan kaç puan <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;kazandığını öğrenmek ister misin? <br />
        <br />
      </span>
      <span style="text-align:center">
        Cevabı için <b style="color:red">Şef Dükkanında</b> videonu hemen izle !</span
      >
      <BrandButton
        class="chefs-shop-button"
        as="router-link"
        :target="chefsShopURL"
        @click.native="virtualVisitDataLayer"
      >
        <VueIcon
          class="virtual-visit-icon"
          :iconName="constants.icons.virtualVisit.name"
          :width="constants.icons.virtualVisit.width"
          :height="constants.icons.virtualVisit.height"
          :iconColor="constants.icons.virtualVisit.color"
          :iconSize="{
            width: constants.icons.eorder.width,
            height: constants.icons.eorder.height,
          }"
        />
        <VueText color="grey-40" sizeLevel="7" weightLevel="5">Şef Dükkanında</VueText></BrandButton
      >
    </div>
  </div>
</template>
<script>
import BrandInfoCard from '@/components/brand/BrandInfoCard/BrandInfoCard.vue';
import BrandSelectModal from '@/components/brand/Modals/BrandModal/BrandSelectModal.vue';
import StaticContent from '@/services/Api/staticContents';
import GameApi from '@/services/Api/gamification';
import VueText from '@/components/shared/VueText/VueText';
import dateUtils from '@/mixins/dateUtils';
import currencyFilter from '@/mixins/currencyFilter.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'PlayWin',
  mixins: [dateUtils, currencyFilter, gtmUtils],
  components: {
    BrandInfoCard,
    BrandSelectModal,
    BrandButton,
    VueIcon,
    VueText,
  },
  data() {
    return {
      activeMarathons: [],
      showDailyMarathonMessage: false,
      dailyMarathonMessage: '',
      chefShopAvailable: false,
      chefsShopURL: `${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`,
      modal: { show: false, title: '', scores: null, points: [] },
    };
  },
  created() {
    this.checkCampaigns();
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        icons: {
          eorder: { ...ICON_VARIABLES.eorder, color: '#ffffff' },
          virtualVisit: { ...ICON_VARIABLES.virtualVisit, color: '#d02d2c' },
        },
      };
    },
  },
  methods: {
    virtualVisitDataLayer() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('sef_dukkaninda', {
        location: 'oyna_kazan',
      });
    },
    checkCampaigns() {
      let cachedMenuData = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
      }).get();
      cachedMenuData?.menuItems?.forEach(element => {
        element.children?.forEach(children => {
          if (
            children.name === 'Şef Dükkanında Özetim' ||
            children.id === 19 ||
            children.screenCode === 18
          ) {
            this.chefShopAvailable = true;
          }
        });
      });
    },
    showInfo(game) {
      this.modal.points = [];
      StaticContent.getStaticContent(game.marathonCode).then(res1 => {
        let {
          Data: { content },
        } = res1.data;
        if (game.marathonId !== -1 && game.marathonGameType != 20) {
          GameApi.getScores(game.marathonGameType).then(res2 => {
            let {
              Data: { points },
            } = res2.data;

            this.modal.points = points;
          });
        }
        this.modal.description = content;
        this.modal.show = true;
        this.modal.title = game.marathonName;
        this.modal.headerClass = 'bg-cover-' + game.bg;
      });

      this.pushDataLayerEvent('competition', {
        name: game.marathonName,
        action: 'click',
      });
    },
    getDailyMarathonMessage() {
      StaticContent.getStaticContent('EmptyMarathonErrorMessage').then(res => {
        let {
          Data: { content },
        } = res.data;

        this.dailyMarathonMessage = content;
      });
    },
    async getCheckActiveMarathons() {
      const result = await GameApi.checkActiveMarathons();
      this.showDailyMarathonMessage = result?.data?.Data.marathon.length === 0;

      const marathonIdColors = {
        '1008': 'yellow',
        '1009': 'red',
        '1010': 'peach',
      };

      this.activeMarathons =
        result?.data?.Data?.marathon?.map(f => ({
          ...f,
          bg: marathonIdColors[f.marathonId] || 'orange',
        })) || [];

      if (this.$route.query.info) {
        let index = this.activeMarathons.findIndex(x =>
          x.marathonCode.toLowerCase().includes(this.$route.query.info.toLowerCase()),
        );
        if (index > -1) {
          this.showInfo(this.activeMarathons[index]);
        }
      }
    },
  },
  beforeMount() {
    this.getDailyMarathonMessage();
    this.getCheckActiveMarathons();
  },
};
</script>
<style lang="scss">
.container {
  &.play-win {
    .wrapper {
      padding: palette-space-level(20);
      display: grid;
      height: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: palette-space-level(20);
      margin-bottom: palette-space-level(40);
    }

    .marathon-modal-wrapper {
      position: fixed;
      .modal-header-yellow {
        background-color: yellow;
      }
      .modal-header-blue {
        background-color: blue;
      }
      .modal-header-peach {
        background-color: peachpuff;
      }
      .modal-header-red {
        background-color: red;
      }

      .scores-container {
        .static-desc {
          padding-top: palette-space-level(20);
          img {
            border: none;
          }
        }
        .scores-title {
          padding-left: palette-space-level(20);
          padding-bottom: palette-space-level(10);
        }
        .scores-table-wrapper {
          padding: palette-space-level(5);
          table {
            th,
            td {
              border: 1px solid palette-color-level('grey', '20');
            }
            width: 100%;

            border-collapse: collapse;
            thead {
              tr {
                th {
                  background-color: palette-color-level('grey', '10');
                  padding: palette-space-level(10);
                  color: palette-color-level('grey', '40');
                  font-size: 13px;
                }
              }
            }
            tbody {
              tr {
                td {
                  padding: palette-space-level(8);
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.virtual-visit {
  font-size: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.chefs-shop-button {
  width: 60% !important;
  margin-top: 45px;
  border-radius: 5px;
  padding: 1rem;
  font-family: 'Avenir Next';
  font-size: 10px !important;
  padding: #000;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(to left, #d20051, #ffa300);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  .virtual-visit-icon {
    margin-right: palette-space-level(12);
  }
}
.player-wrapper {
  margin: 60px 10px;
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  #ommaPlayer {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.daily_marathon_message {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding-bottom: 50px;
}
</style>
